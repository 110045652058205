<template>
  <div
    class="image-with-fallback__container"
  >
    <img
      v-if="canDisplayImage"
      v-bind="{...imgAttributes}"
    >
    <v-skeleton-loader
      v-else
      type="image"
    />
  </div>
</template>
<script>

import { http } from '@/plugins/axios';

export default {
  name: "ImageWithFallback",

  data() {
    return {
      canDisplayImage: false,
    };
  },

  created() {
    this.id = setInterval(
      () => this.loadImage(this.imgAttributes.src), this.$options.constants.IMAGE_FETCH_DELAY);
  },

  methods: {
    async loadImage(imageUrl) {
      if (this.fetchImageAttempts >= this.$options.constants.IMAGE_FETCH_MAX_RETRIES) {
        clearInterval(this.id);
        return;
      }

      this.fetchImageAttempts += 1;
      try {
        await http.get(imageUrl, {ignoreGlobalCatch: true});
        this.canDisplayImage = true;
        this.$emit('loaded');
        clearInterval(this.id);
      } catch (error) {
        this.canDisplayImage = false;
      }
    },
  },

  constants: {
    IMAGE_FETCH_DELAY: 1000, // 1 second
    IMAGE_FETCH_MAX_RETRIES: 5,
  },

  props: {
    imgAttributes: {
      type: Object,
      required: true,
    },
  },

  emits: [ 'loaded' ],
}

</script>
