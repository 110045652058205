<template>
  <div class="classifier-info page-padding py-7">
    <div
      class="clickable bottom-gap"
      @click="$router.push('/extract/studio/models/classification')"
    >
      <v-icon
        class="inline-middle right-gap"
        color="primary"
      >
        fas fa-chevron-left
      </v-icon>
      <h4 class="inline-middle">
        {{ $t('classifiers.all') }}
      </h4>
    </div>
    <HeaderName
      :item="classifier"
      :editable="false"
    />
    <v-card class="elevation-6 pa-0 top-gap">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col>
            {{ $t('classifiers.categories') }}
          </v-col>
          <v-col style="text-align: right">
            {{ $t('classifiers.initial') }}
          </v-col>
          <v-col style="text-align: right">
            {{ $t('classifiers.training_score') }}
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="stats.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px; opacity: 0.5"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in stats"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col>
            {{ item.name }}
          </v-col>
          <v-col style="text-align: right">
            <div class="model__blue">
              {{ item.initial }}
            </div>
          </v-col>
          <v-col style="text-align: right">
            <div class="model__blue">
              {{ item.initial ? `${(item.score * 100).toFixed(2)}%` : '-' }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div class="top-gap">
      {{ $t('classifiers.confusion') }}
    </div>
    <v-card class="elevation-6 pa-0 top-gap">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col>
            {{ $t('classifiers.wait_predict') }}
          </v-col>
          <v-col
            v-for="category in confusion.categories"
            :key="category.name"
            class="text-center"
          >
            {{ category.name }}
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="stats.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px; opacity: 0.5"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in confusion.categories"
          :key="item.name"
          class="table-row fade-in table-row-height"
        >
          <v-col>
            {{ item.name }}
          </v-col>
          <template v-for="(td, indexName) in item">
            <template v-if="indexName !== 'name'">
              <template
                v-for="(col, id) in td"
                :key="id"
              >
                <v-col>
                  <div class="confusion-col">
                    <p
                      class="confusion-col__number text-center"
                      :class="[
                        col.name === item.name
                          ? 'confusion-col__number--green'
                          : null,
                        col.score >= 1 ? 'confusion-col__number--red' : null,
                      ]"
                    >
                      {{ col.score }}
                    </p>
                  </div>
                </v-col>
              </template>
            </template>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';

import HeaderName from '@/components/common/elements/General/HeaderName';

export default {
  name: 'ClassifierInfo',

  components: { HeaderName },

  data() {
    return ({
      stats: [],
      confusion: {categories: []},
    });
  },

  computed: {
    classifier() {
      return ({
        name: this.$route.params.name,
      });
    }
  },

  async mounted() {
    this.getStats();
    this.getConfusion();
  },

  methods: {
    async getStats() {
      try {
        const response = await http.get(
          `classify/api/v1/docs/models/statistics/?name=${this.classifier.name}`
        );
        this.stats = response.data;
      } catch (error) {
        this.$router.push({ name: '404Redirect' });
      }
    },

    async getConfusion() {
      try {
        const response = await http.get(
          `classify/api/v1/docs/models/confusion/?name=${this.classifier.name}`
        );
        this.confusion = response.data;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.classifier-info {
  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }

  .confusion-col {
    padding: 0px;
    max-width: rem(100);
    margin: 0 auto;
    text-align: center;

    &__number {
      &--red,
      &--green {
        padding: 12px 20px;
        border-radius: 8px;
        margin-top: -14px;
      }

      &--red {
        background-color: #f78b98;
      }

      &--green {
        background-color: #cfe5b5;
      }
    }
  }

  .model {
    &__blue {
      position: relative;
      z-index: 2;
      text-align: right;
      background-color: #DDEDFE;
      height: 90%;

      &::before {
        content: "";
        position: absolute;
        top: -16px;
        right: -16px;
        bottom: -16px;
        left: -16px;
        background-color: #DDEDFE;
        z-index: -1;
      }

      &::after {
        content: "";
        position: absolute;
        top: calc(100% + 16px);
        left: -16px;
        right: -16px;
        background-color: var(--v-tip-darken1);
        height: 1px;
      }
    }
  }
}
</style>
