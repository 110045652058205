<template>
  <div class="resetPassword">
    <modify-password
      v-if="userId"
      :org-id="orgId"
      :user-id="userId"
      :check-current-password="false"
      @submit="editPassword"
    />
  </div>
</template>

<script>
import { AuthAPI } from '@/API/authenticator/AuthAPI';
import { PasswordAPI } from '@/API/authenticator/PasswordAPI';
import jwt_decode from 'jwt-decode';
import ModifyPassword from '@/components/common/elements/Password/ModifyPassword';

export default {
  name: 'ResetPassword',
  components: { ModifyPassword },
  data() {
    return {
      token: null,
      orgId: null,
      userId: null,
      policy: null,
    };
  },

  mounted() {
    if (!this.$route.params.token) {
      this.$router.push({ name: 'documents' });
    }
    this.token = this.$route.params.token;
    const tokenDecoded = jwt_decode(this.$route.params.token);
    if (tokenDecoded.org_id === undefined || !tokenDecoded.org_id) {
      this.$router.push({ name: 'documents' });
    }
    this.userId = tokenDecoded.user_id;
    this.orgId = tokenDecoded.org_id;
  },

  methods: {
    async editPassword(form) {
      try {
        await PasswordAPI.passwordRecoveryConfirm(this.token, form.new_password);
        await AuthAPI.revoke({ access_token: this.token });
        this.$store.commit('setSuccessMessage', this.$t('account.changePassword.success'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$router.push({ name: 'ExtractLogin' });
      } catch(error) {
        console.log(error);
        this.$store.commit('setSnackbar', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resetPassword {
  margin-top: 60px;
  height: 100%;
}
</style>
