<template>
  <div class="search-results-column right-gap-lg">
    <div class="d-flex">
      <v-text-field
        v-model="$store.state.searchString"
        class="mt-0 right-gap"
        clear-icon="fas fa-times"
        variant="outlined"
        color="primary"
        density="compact"
        :aria-placeholder="$t('search.placeholder')"
        :disabled="loading"
        :placeholder="$t('search.placeholder')"
        @click:clear="$store.state.searchString = ''"
        @keyup.enter="$emit('search')"
        clearable
      />
      <v-btn
        style="margin-top: 3px; height: 40px;"
        color="primary"
        @click="$emit('search')"
        rounded
      >
        <v-icon>fas fa-search</v-icon>
      </v-btn>
    </div>
    <div
      style="margin-top: -20px"
      class="bottom-gap-sm"
    >
      <small
        class="clickable"
        style="color: rgb(var(--v-theme-primary))"
        @click="$store.commit('setSearchShowFilters', !showFilters)"
      >
        {{ showFilters ? $t('search.hide_filters') : $t('search.show_filters') }}
      </small>
    </div>
    <SearchRecentSearches
      v-if="!startedSearch"
      @search="$emit('search')"
    />
    <SearchRecentFiles v-if="!startedSearch" />
    <div v-if="!startedSearch">
      <h4 class="bottom-gap">
        <v-icon style="margin-right: 5px">
          fas fa-star
        </v-icon>
        <span style="position: relative; top: 2px;">
          Favorites
        </span>
      </h4>
      <div>
        <v-card>
          <div v-if="favorites.length === 0">
            <i>You have not added any favorites yet.</i>
          </div>
          <div
            v-for="folder in favoriteFolders.filter(f => f.favorites.length > 0)"
            :key="folder.id"
          >
            <div class="d-flex bottom-gap-sm"> 
              <v-icon class="mr-2 bottom-gap-sm">
                fas fa-folder
              </v-icon>
              <ItemName
                class="noselect inline-middle"
                :item="folder"
                :editing-allowed="false"
                :show-id="false"
                :fade-in-slow="false"
                :clickable="false"
                :max-width="200"
              />
              <div class="ml-1">
                ({{ folder.favorites.length }})
              </div>
              <ChevronButton
                v-model="folder.open"
                class="ml-1"
                :size="14"
              />
            </div>
            <div
              v-if="folder.open"
              class="bottom-gap"
            >
              <a
                v-for="favorite in folder.favorites"
                :key="favorite.id"
                target="_blank"
                style="text-decoration: none"
                :href="favorite.file_url"
              >
                <ItemName
                  class="noselect bottom-gap-sm"
                  style="max-width: 400px"
                  :item="{ name: favorite.file_name }"
                  :editing-allowed="false"
                  :show-id="false"
                  :fade-in-slow="false"
                  :max-width="400"
                />
              </a>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <LoadingIcon v-else-if="loading" />
    <SearchResultsLLM
      v-else-if="llmActive"
      :favorites="favorites.map(f => f.file_id)"
      @remove-from-favorites="removeFromFavorites"
    />
    <SearchResultsLegacy
      v-else
      :favorites="favorites.map(f => f.file_id)"
      @remove-from-favorites="removeFromFavorites"
    />
  </div>
</template>

<script>
import { FavoriteAPI } from '@/API/search/FavoriteAPI';

import SearchRecentFiles from "@/components/search/elements/Home/SearchRecentFiles";
import SearchRecentSearches from "@/components/search/elements/Home/SearchRecentSearches";
import SearchResultsLegacy from "@/components/search/views/Home/SearchResultsLegacy";
import SearchResultsLLM from "@/components/search/views/Home/SearchResultsLLM";
import LoadingIcon from "@/components/search/elements/common/LoadingIcon";
import ItemName from '@/components/common/elements/General/ItemName';
import ChevronButton from "@/components/common/elements/General/ChevronButton";


export default {
  name: 'SearchResultsColumn',

  components: {
    LoadingIcon,
    SearchRecentFiles,
    SearchRecentSearches,
    SearchResultsLegacy,
    SearchResultsLLM,
    ItemName,
    ChevronButton,
  },

  computed: {
    selectedFavoriteFolder() {
      const selected = this.favoriteFolders.find(f => f.selected);
      return selected;
    },

    favoriteFolders() {
      return this.$store.getters.searchFavoriteFolders;
    },

    startedSearch() {
      return this.$store.getters.startedSearch;
    },

    searchTotalFiles() {
      return this.$store.getters.searchTotalFiles;
    },

    showFilters() {
      return this.$store.getters.searchShowFilters;
    },

    favorites() {
      const favorites = [];
      this.favoriteFolders.forEach(folder => {
        folder.favorites.forEach(document => {
          favorites.push(document);
        });
      });
      return favorites;
    },
  },

  mounted() {
    this.getFavoriteFolders();
  },

  methods: {
    async getFavoriteFolders() {
      try {
        const folders = await FavoriteAPI.getFolders();
        this.$store.commit('setSearchFavoriteFolders', folders);
      } catch (err) {
        console.log(err);
      }
    },

    async removeFromFavorites(docId) {
      const favorite = this.favorites.find(f => f.file_id === docId);
      try {
        await FavoriteAPI.removeFavorite(favorite.id);
        this.getFavoriteFolders();
        this.$store.commit(
          'setSuccessMessage',
          this.$t('search.home.removed_from_favorites')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (err) {
        console.log(err);
      }
    },
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    llmActive: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['search'],
}
</script>

<style lang="scss" scoped>
.search-results-column {
  width: 40vw;

  h1 {
    display: inline-block;
  }

  h3 {
    display: inline-block;
  }
}
</style>
