<template>
  <div class="configure-business-rule page-padding py-7">
    <BreadcrumbComponent v-if="!external" />
    <div style="width: 1110px">
      <v-text-field
        ref="nameField"
        v-model="rule.name"
        class="right-gap filter-items inline-middle"
        style="width: 280px !important"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('businessRules.type_name')"
        @keydown.enter="addNew"
      />
      <div class="top-gap-sm">
        <div
          class="label"
          style="margin-bottom: -21px"
        >
          {{ $t('businessRules.function') }}
        </div>
        <div class="d-flex top-gap">
          <div class="inline-middle radio-box right-gap-sm">
            <v-checkbox
              v-model="rule.is_validation"
              class="mr-4"
              style="margin-top: -7px"
              color="primary"
              :label="$t('businessRules.validation')"
            />
          </div>
          <div class="inline-middle radio-box right-gap-sm">
            <v-checkbox
              v-model="rule.is_integrity"
              class="mr-4"
              style="margin-top: -7px"
              color="primary"
              :label="$t('businessRules.integrity')"
            />
          </div>
          <div class="inline-middle radio-box right-gap-sm">
            <v-checkbox
              v-model="rule.is_workflow"
              class="mr-4"
              style="margin-top: -7px"
              color="primary"
              :label="$t('businessRules.workflow')"
              :style="{opacity: rule.type === 'subgroup' ? 0.5 : 1}"
              :disabled="rule.type === 'subgroup'"
              @change="completeCheck++"
            />
          </div>
        </div>
        <div
          class="label top-gap-sm"
          style="margin-bottom: -12px"
        >
          {{ $t('businessRules.type') }}
        </div>
        <v-radio-group
          v-model="rule.type"
          :disabled="id !== 'new'"
          @update:model-value="handleTypeChange"
          inline
        >
          <div
            v-for="ruleType, i in ruleTypes"
            :key="i"
            class="radio-box right-gap-sm"
          >
            <v-radio
              :label="ruleType.text"
              :value="ruleType.value"
              :style="{opacity: (id !== 'new' && ruleType.value !== rule.type) || (ruleType.value === 'subgroup' && rule.is_workflow) ? 0.5 : 1}"
              :disabled="ruleType.value === 'subgroup' && ruleType.is_workflow"
            />
          </div>
        </v-radio-group>
        <div
          v-if="loaded && id !== 'new' && rule.status === 'incomplete'"
          class="info-box bottom-gap fade-in"
        >
          <small>
            <div
              class="inline-middle"
              style="width: 40px"
            >
              <v-icon
                class="info-icon"
                size="16"
              >
                fas fa-info-circle
              </v-icon>
            </div>
            <div
              class="inline-middle"
              style="width: calc(100% - 40px)"
            >
              {{ $t('businessRules.incomplete_message') }}
            </div>
          </small>
        </div>
        <div
          v-if="loaded && id !== 'new' && rule.status === 'error'"
          class="info-box bottom-gap top-gap-sm fade-in"
        >
          <small>
            <div
              class="inline-middle"
              style="width: 40px"
            >
              <v-icon
                class="info-icon"
                size="16"
              >
                fas fa-info-circle
              </v-icon>
            </div>
            <div
              class="inline-middle"
              style="width: calc(100% - 40px)"
            >
              {{ $t('businessRules.error') }}
            </div>
          </small>
        </div>
        <div
          v-if="loaded && rule.type === 'internal'"
          class="fade-in"
          style="margin-top: -10px"
        >
          <div
            class="label"
            style="margin-bottom: 3px"
          >
            {{ $t('options') }}
          </div>
          <div class="inline-middle radio-box right-gap-sm">
            <v-checkbox
              v-model="rule.na_zero"
              color="primary"
              style="margin-top: -7px"
              :label="$t('businessRules.na_zero')"
            />
          </div>
          <InternalRuleEditor
            ref="internalRuleEditor"
            class="top-gap"
            style="margin-bottom: 110px"
            :rule="rule"
            @validity-change="completeCheck++"
          />
        </div>
        <div
          v-if="loaded && rule.type === 'subgroup'"
          class="fade-in"
          style="margin-top: -10px"
        >
          <div
            class="label"
            style="margin-bottom: 3px"
          >
            {{ $t('options') }}
          </div>
          <div class="inline-middle radio-box right-gap-sm">
            <v-checkbox
              v-model="rule.na_zero"
              color="primary"
              style="margin-top: -7px"
              :label="$t('businessRules.na_zero')"
            />
          </div>
          <InternalRuleEditor
            ref="subgroupRuleEditor"
            class="top-gap"
            style="margin-bottom: 110px"
            type="subgroup"
            :rule="rule"
            @validity-change="completeCheck++"
          />
        </div>
        <div
          v-if="loaded && rule.type === 'external'"
          class="fade-in"
        >
          <div
            class="label"
            style="margin-bottom: 3px; margin-top: -10px"
          >
            {{ $t('options') }}
          </div>
          <div class="inline-middle radio-box right-gap-sm">
            <v-checkbox
              v-model="rule.generative_fallback"
              color="primary"
              style="margin-top: -7px"
              :label="$t('businessRules.generative_fallback')"
            />
          </div>
          <div
            class="label top-gap-sm"
            style="margin-bottom: -12px"
          >
            {{ $t('businessRules.api') }}
          </div>
          <v-radio-group
            v-model="apiType"
            @update:model-value="handleApiTypeChange"
            inline
          >
            <div
              v-for="apiTypeItem, i in apiTypes"
              :key="i"
              class="radio-box right-gap-sm"
            >
              <v-radio
                :label="apiTypeItem.text"
                :value="apiTypeItem.value"
              />
            </div>
          </v-radio-group>
          <v-select
            v-if="apiType === 'predefined'"
            v-model="predefinedUrl"
            style="width: 300px; margin-top: -10px !important"
            class="inline-middle"
            variant="outlined"
            color="primary"
            density="compact"
            item-value="value"
            item-title="text"
            :items="predefinedUrls"
            @update:model-value="handlePredefinedChange"
          />
          <UrlField
            v-if="apiType === 'custom'"
            v-model="rule.url"
            style="margin-top: -10px"
            @input="completeCheck++"
          />
          <div
            v-if="apiType === 'custom'"
            style="margin-bottom: -20px"
          >
            <div
              class="label"
              style="margin-bottom: -12px; margin-top: -10px;"
            >
              {{ $t('businessRules.token') }}
            </div>
            <v-text-field
              v-model="rule.token"
              class="bottom-gap-sm"
              style="width: 300px"
              clear-icon="fas fa-times"
              variant="outlined"
              color="primary"
              density="compact"
              clearable
            />
          </div>
          <div
            v-if="apiType === 'predefined'"
            class="info-box bottom-gap-sm top-gap-sm"
            style="width: 603px"
          >
            <small>
              <div
                class="inline-middle"
                style="width: 30px"
              >
                <v-icon
                  class="info-icon"
                  size="16"
                >
                  fas fa-info-circle
                </v-icon>
              </div>
              <div
                class="inline-middle"
                style="width: calc(100% - 30px)"
              >
                {{ $t('businessRules.compatible') }}
                <span
                  v-for="predefinedType, i in selectedPredefined.type"
                  :key="i"
                >
                  <strong>{{ $t(`businessRules.valueTypes.${predefinedType}`) }}</strong>{{ selectedPredefined.type.length - 1 > i ? ',' : '.' }}
                </span>
              </div>
            </small>
          </div>
          <div
            class="label"
            style="margin-bottom: -10px"
          >
            {{ $t('businessRules.data_point_name') }}
          </div>
          <v-select
            v-model="rule.data_point_id"
            class="inline-middle"
            variant="outlined"
            color="primary"
            density="compact"
            style="width: 300px"
            item-value="id"
            item-title="name"
            :disabled="dataPoints.length === 0"
            :items="dataPoints"
            :placeholder="dataPoints.length === 0 ? $t('businessRules.no_data_points') : $t('businessRules.select_data_point')"
            @update:model-value="completeCheck++"
          />
          <div
            class="radio-box inline-middle left-gap-sm"
            style="width: fit-content; height: 42px; margin-top: 15px"
          >
            <v-checkbox
              v-model="rule.normalization"
              style="margin-top: -7px"
              color="primary"
              :label="$t('businessRules.normalize')"
              :disabled="apiType === 'predefined'"
              :style="{opacity: apiType === 'predefined' ? 0.5 : 1}"
              @change="completeCheck++"
            />
          </div>
          <div v-if="apiType === 'custom' && selectedDatapoint">
            <div
              class="info-box top-gap-sm"
              style="width: 603px"
            >
              <small>
                <div
                  class="inline-middle"
                  style="width: 30px"
                >
                  <v-icon
                    class="info-icon"
                    size="16"
                  >
                    fas fa-info-circle
                  </v-icon>
                </div>
                <div
                  class="inline-middle"
                  style="width: calc(100% - 30px)"
                >
                  {{ $t('businessRules.apiMessage') }}
                  <span v-if="!rule.normalization">
                    {{ "{ 'result': bool }" }}
                  </span>
                  <span v-else>
                    {{ "{ 'result': bool, 'normalization': " }}{{ $t(`businessRules.apiValueTypes.${selectedDatapoint.value_type}`) }}{{ $t('businessRules.normalization_message') }}
                  </span>
                </div>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: right">
        <v-btn
          class="save-button top-gap-sm"
          style="box-shadow: none; padding-left: 15px; padding-right: 15px; margin-right: 260px"
          color="primary"
          :disabled="!isComplete"
          @click="id === 'new' ? addNew() : saveRule()"
          rounded
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import jwtDecode from "jwt-decode";
import doc_type_mixin from "@/mixins/document_type.js";
import validation_mixin from "@/mixins/validation.js";
import model_mixin from "@/mixins/model.js";

import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import UrlField from '@/components/common/elements/Forms/UrlField';
import InternalRuleEditor from "@/components/extract/elements/BusinessRules/InternalRuleEditor";
import { AuthenticationUtils } from '../../../../utils/AuthenticationUtils';

export default {
  name: 'ConfigureBusinessRule',

  mixins: [
    doc_type_mixin,
    validation_mixin,
    model_mixin,
  ],

  components: {
    BreadcrumbComponent,
    UrlField,
    InternalRuleEditor,
  },

  data() {
    return ({
      apiType: 'predefined',
      apiTypes: [
        {
          value: 'predefined',
          text: this.$t('predefined'),
        },
        {
          value: 'custom',
          text: this.$t('custom'),
        },
      ],
      predefinedUrl: 'https://api-adresse.data.gouv.fr/search/',
      predefinedUrls: [
        {
          value: 'https://api-adresse.data.gouv.fr/search/',
          text: this.$t('businessRules.french_address'),
          type: ['unspecified', 'regex'],
          normalization: true,
        },
        {
          value: 'https://api.insee.fr/entreprises/sirene/V3/siren/',
          text: 'SIREN',
          type: ['unspecified', 'regex'],
          normalization: false,
        },
      ],
      id: -1,
      loaded: false,
      rule: {
        name: '',
        na_zero: false,
        document_type_id: this.$route.params.id,
        type: 'internal',
        logic: null,
      },
      completeCheck: 0,
      ruleTypes: [
        {
          value: 'internal',
          text: this.$t('businessRules.internal'),
        },
        {
          value: 'subgroup',
          text: this.$t('businessRules.subgroup'),
        },
        {
          value: 'external',
          text: this.$t('businessRules.external'),
        },
      ],
    });
  },

  computed: {
    selectedPredefined() {
      if (this.apiType === 'predefined') {
        const selected = this.predefinedUrls.find(u => u.value === this.predefinedUrl);
        return selected || null;
      }
      return null;
    },

    selectedDatapoint() {
      this.completeCheck;
      if (this.rule.type === 'external') {
        const selected = this.dataPoints.find(dp => dp.id === this.rule.data_point_id);
        return selected || null;
      }
      return null;
    },

    type: {
      get() {
        if (this.$store.getters.documentTypes[this.$route.params.id]) {
          return this.$store.getters.documentTypes[this.$route.params.id];
        }
        return { name: "" };
      },
      set(newType) {
        this.$store.commit("setDocumentType", newType);
      },
    },

    dataPoints: {
      cache: false,
      get: function () {
        const dataPoints = this.$store.getters.dataPoints[this.$route.params.id];
        if (dataPoints) {
          if (this.rule.type === 'internal') {
            return dataPoints.filter(dp => ['integer', 'float'].includes(dp.value_type));
          } else if (this.selectedPredefined) {
            return dataPoints.filter(dp => this.selectedPredefined.type.includes(dp.value_type));
          }
          return dataPoints;
        }
        return [];
      },
      set: function (newDataPoints) {
        this.$store.commit("setDataPoints", {
          typeId: this.$route.params.id,
          dataPoints: newDataPoints,
        });
      },
    },

    filteredGroups() {
      let groups = [...this.labelGroups]
      groups = groups.filter(g => g.labels.some(l => ['integer', 'float'].includes(g.value_types[g.labels.indexOf(l)])));
      return groups;
    },

    labelGroups: {
      cache: false,
      get: function () {
        let groups = [...this.$store.getters.labelGroups[this.$route.params.id]];
        if (groups) {
          groups = groups.filter(g => g.labels.some(l => ['integer', 'float'].includes(g.value_types[g.labels.indexOf(l)])));
          return groups;
        }
        return [];
      },
      set: function (newGroups) {
        this.$store.commit("setLabelGroups", {
          typeId: this.$route.params.id,
          labelGroups: newGroups,
        });
      },
    },

    isComplete() {
      this.completeCheck;
      if (!this.rule.name || this.rule.name.trim().length === 0) {
        return false;
      }
      if (
        this.rule.type === 'internal'
        && this.$refs.internalRuleEditor
        && this.$refs.internalRuleEditor.codeErrors.length > 0
      ) {
        return false;
      }
      if (
        this.rule.type === 'subgroup'
        && this.$refs.subgroupRuleEditor
        && this.$refs.subgroupRuleEditor.codeErrors.length > 0
      ) {
        return false;
      }
      if (
        this.rule.type === 'external'
        && (
          this.dataPoints.length === 0
          || (this.apiType === 'custom' && (!this.rule.url || this.rule.url === '' || this.rule.url === 'http://' || this.rule.url === 'https://'))
        )
      ) {
        return false;
      }
      return true;
    },

    external() {
      return !!this.$route.params.token;
    },
  },

  watch: {
    id(id) {
      this.setBreadcrumb(id);
    },

    type() {
      this.setBreadcrumb(this.id);
    },
  },

  async mounted() {
    if (this.external) {
      const tokenDecoded = jwtDecode(this.$route.params.token);
      if (!tokenDecoded.org_id || !tokenDecoded.business_rule_id) {
        this.$router.push({ name: "documents" });
      }
      const now = new Date();
      this.expired = AuthenticationUtils.getExpDateFromToken(tokenDecoded) < now;
      if (!this.expired) {
        this.id = tokenDecoded.business_rule_id;
      } else {
        this.$router.push({ name: "documents" });
      }
    } else {
      this.id = this.$route.params.ruleid;
    }
    if (this.type.name === '') {
      await this.getDocType();
      await this.getDataPoints();
      await this.getLabelGroups();
    }
    if (this.id !== 'new') {
      await this.getBusinessRule(this.id);
      if (this.rule.type === 'external') {
        const url = this.predefinedUrls.find(url => url.value === this.rule.url);
        if (url) {
          this.apiType = 'predefined';
          this.predefinedUrl = url.value;
        } else {
          this.apiType = 'custom';
        }
      }
    } else {
      this.setNewRule();
    }
    this.loaded = true;
  },

  methods: {
    setBreadcrumb(id) {
      const breadcrumbPath = [
        { title: this.$t('breadcrumb.home'), href: {name: 'Extract'} },
        { title: this.$t('breadcrumb.docTypes'), href: {name: 'Extract'} },
        {
          title: this.type.name,
          href: {
            name: 'DocType',
            params: {
              id: this.$route.params.id,
              tab: 'rules',
            },
          }
        },
      ];
      let current = { title: this.$t('breadcrumb.configuration') };
      if (id === 'new') {
        current = { title: this.$t('businessRules.create') };
        setTimeout(() => {
          const nameField = this.$refs[`nameField`];
          if (nameField) {
            nameField.focus();
          }
        }, 10);
      }
      breadcrumbPath.push(current);
      this.$store.commit('setBreadcrumb', breadcrumbPath);
    },

    handlePredefinedChange() {
      setTimeout(() => {
        this.rule.normalization = this.selectedPredefined.normalization;
      }, 10);
    },

    setNewRule() {
      this.rule.logic = {};
      this.rule.is_validation = true;
      this.rule.is_integrity = false;
      this.rule.is_workflow = false;
    },

    async saveRule() {
      this.$store.commit('setLoadingScreen', true);
      let rule = this.rule;
      if (this.rule.type === 'external'){
        let exclude = new Set(['logic']);
        rule = Object.fromEntries(Object.entries(this.rule).filter(e => !exclude.has(e[0])));
        if (this.apiType === 'predefined') {
          rule.url = this.predefinedUrl;
        }
        this.rule = rule;
      }
      try {
        await http.put(
          `system_2/business_rule/${this.rule.id}/`,
           this.rule,
           {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        await this.$store.commit(
          'setSuccessMessage', this.$t('businessRules.saved')
        );
        this.$store.commit('setSuccessSnackbar', true);
        if (!this.external) {
          this.$router.push({
            name: 'DocType',
            params: {
              id: this.$route.params.id,
              tab: 'rules',
            },
          });
        }
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    handleTypeChange(type) {
      if (type === 'external') {
        if (!this.rule.data_point_id) {
          this.rule.data_point_id = this.dataPoints.length > 0 ? this.dataPoints[0].id : null;
        }
        if (!this.rule.url) {
          this.rule.url = '';
          this.rule.token = '';
        }
        this.rule.normalization = true;
        this.rule.logic = null;
        this.rule.extraction_group_id = null;
      } else if (type === 'internal') {
        this.setNewRule();
        this.rule.normalization = null;
        this.rule.url = null;
        this.rule.token = null;
        this.rule.data_point_id = null;
        this.rule.extraction_group_id = null;
      } else if (type === 'subgroup') {
        this.setNewRule();
        this.rule.normalization = null;
        this.rule.url = null;
        this.rule.token = null;
        this.rule.data_point_id = null;
        this.completeCheck++;
      }
    },

    handleApiTypeChange(type) {
      if (type === 'predefined') {
        this.rule.url = this.predefinedUrls[0].value;
        this.rule.token = '';
        this.rule.normalization = this.predefinedUrls[0].normalization;
      } else {
        this.rule.url = '';
        this.rule.token = '';
        this.rule.normalization = false;
      }
      this.completeCheck++;
      },

    async addNew() {
      let rule = this.rule;
      if (this.rule.type === 'external'){
        let exclude = new Set(['logic']);
        rule = Object.fromEntries(Object.entries(this.rule).filter(e => !exclude.has(e[0])));
        if (this.apiType === 'predefined') {
          rule.url = this.predefinedUrl;
        }
      }
      try {
        this.$store.commit('setLoadingScreen', true);
        const {data: id} = await http.post(
          'system_2/business_rule/', { name: rule.name.trim(), ...rule }
        );
        const responseGet = await http.get(`system_2/business_rule/${id}`);
        this.rule = responseGet.data;
        this.id = this.rule.id;
        this.$store.commit('setSuccessMessage', this.$t('businessRules.created'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        this.$router.push({
        name: 'DocType',
        params: {
          id: this.$route.params.id,
          tab: 'rules',
        },
      });
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async getBusinessRule(id) {
      this.$store.commit('setLoadingScreen', true);
      try {
        const responseGet = await http.get(
          `system_2/business_rule/${id}`,
          {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        this.rule = responseGet.data;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.configure-business-rule {
  height: fit-content;

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 13px 24px 20px;
    width: 850px;

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }
}
</style>
