import { createRouter, createWebHistory } from 'vue-router';
import TokenValidationView from '@/components/common/views/TokenValidation/TokenValidationView';
import CreateDoctypeView from '@/components/extract/views/DocTypes/CreateDoctypeView';
import NewTypeView from '@/components/extract/views/DocTypes/NewTypeView';
import CopyTypeView from '@/components/extract/views/DocTypes/CopyTypeView';
import ExtractionAgents from '@/components/extract/views/DocTypes/ExtractionAgents';
import DatapointView from '@/components/extract/views/Extractors/DatapointView';
import FromModel from '@/components/extract/views/DocTypes/FromModel';
import DocTypeView from '@/components/extract/views/DocType/DocTypeView';
import SettingsView from '@/components/extract/views/Settings/SettingsView';
import WorkflowsView from '@/components/extract/views/Workflows/WorkflowsView';
import ConfigureBusinessRule from '@/components/extract/views/BusinessRules/ConfigureBusinessRule';
import ConfigureWorkflow from '@/components/extract/views/Workflows/ConfigureWorkflow';
import ConfigureMailbox from '@/components/extract/views/Workflows/Mailboxes/ConfigureMailbox';
import CorrectionsView from '@/components/extract/views/Corrections/CorrectionsView';
import CorrectionDocsView from '@/components/extract/views/Corrections/CorrectionDocsView';
import ReviewDocView from '@/components/extract/views/Corrections/ReviewDocView';
import RecycleBinView from '@/components/extract/views/RecycleBin/RecycleBinView';
import LoginView from '@/components/common/views/Login/LoginView';
import LogoutView from '@/components/common/views/Logout/LogoutView';
import LostPassword from '@/components/common/views/Password/LostPassword';
import ResetPassword from '@/components/common/views/Password/ResetPassword';
import ProfilePassword from '@/components/common/views/Settings/ProfilePassword';
import UsersView from '@/components/common/views/Users/UsersView';
import WelcomeView from '@/components/extract/views/DocTypes/WelcomeView';
import LabelGroupView from '@/components/extract/views/Extractors/LabelGroupView';
import ExtractionView from '@/components/extract/views/Studio/ExtractionView';
import ExtractionTrainingView from '@/components/extract/views/Studio/ExtractionTrainingView';
import ClassificationView from '@/components/extract/views/Studio/ClassificationView';
import ClassificationTrainingView from '@/components/extract/views/Studio/ClassificationTrainingView';
import ClassifierInfo from '@/components/extract/views/Studio/ClassifierInfo';
import DatasetsView from '@/components/extract/views/Studio/DatasetsView';
import DatasetTable from '@/components/extract/elements/Datasets/DatasetTable';
import AnnotationView from '@/components/extract/views/Studio/AnnotationView';
import StatsView from '@/components/extract/views/Stats/StatsView';
import TracesView from '@/components/extract/views/Stats/TracesView';
import NotificationsView from '@/components/common/views/Notifications/NotificationsView';
import NotFoundView from '@/components/common/views/404/NotFoundView';
import NotFoundViewRedirect from '@/components/common/views/404/NotFoundViewRedirect';
import SearchDocView from '@/components/search/views/Home/SearchDocView';
import SearchQueryView from '@/components/search/views/Query/SearchQueryView';
import SearchSettings from '@/components/search/views/Settings/SearchSettingsView';
import RedirectView from '@/components/extract/views/Workflows/Mailboxes/RedirectView';
import PageCorrectionView from '@/components/extract/views/Workflows/PageCorretion/PageCorrectionView';
import ClassifyHomeView from '@/components/classify/views/Home/ClassifyHomeView';
import ClassifySettings from '@/components/classify/views/Settings/ClassifySettingsView';
import JobHistory from '@/components/extract/views/Workflows/JobHistory';

import { AuthenticationUtils } from '../utils/AuthenticationUtils';
import { ProductHealthCheck } from "@/utils/ProductHealthCheck";
import { store } from '@/plugins/store';

function prefixRoutesWith(list, prefix) {
  return list.map(route => {
    const modifiedRoute =  {
      ...route,
      path: prefix + route.path,
    };

    if (route.children) {
      modifiedRoute.children = prefixRoutesWith(route.children, prefix);
    }

    return modifiedRoute;
  });
}

// eslint-disable-next-line
const product = process.env.VUE_APP_DEFAULT_HOME_URL || 'extract';
const redirectPath = `${product.toLowerCase()}/`;

const commonRoutes = [
  {
    path: '',
    redirect: redirectPath,
    meta: {},
  },
  {
    path: '/',
    name: 'Home',
    redirect: redirectPath,
    meta: {},
  },
  {
    path: '/reset-password/:token?',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      menu: false,
    },
  },
  {
    path: '/logout',
    component: LogoutView,
    name: 'logout',
    meta: {
      roles: ['orgadmin', 'sysadmin', 'basic'],
      menu: false,
    },
  },
  {
    path: '/404',
    name: '404Redirect',
    component: NotFoundViewRedirect,
    meta: {
      menu: false,
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: NotFoundView,
    meta: {
      menu: false,
    },
  },
];

const extractRoutes = [
  {
    path: '/',
    name: 'Extract',
    meta: {
      roles: ['orgadmin', 'sysadmin', 'basic'],
      menu: true,
    },
  },
  {
    path: '/home',
    name: 'ExtractHome',
    component: WelcomeView,
    meta: {
      roles: ['sysadmin', 'orgadmin', 'basic'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction',
    name: 'ExtractionAgents',
    component: ExtractionAgents,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/create',
    name: 'CreateDoctype',
    component: CreateDoctypeView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/new',
    name: 'newtype',
    component: NewTypeView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/duplicate',
    name: 'copytype',
    component: CopyTypeView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/login',
    name: 'ExtractLogin',
    component: LoginView,
    meta: {
      menu: false,
    },
  },
  {
    path: '/login/:org_id',
    name: 'ExtractLoginOrg',
    component: LoginView,
    meta: {
      menu: false,
    },
  },
  {
    path: '/login/org/:org_slug',
    name: 'ExtractLoginOrgSlug',
    component: LoginView,
    meta: {
      menu: false,
    },
  },
  {
    path: '/openid/:provider/callback',
    name: 'TokenValidation',
    component: TokenValidationView,
    meta: {
      menu: false,
    },
  },
  {
    path: '/lost-password',
    name: 'LostPassword',
    component: LostPassword,
    meta: {
      menu: false,
    },
  },
  {
    path: '/studio/agents/extraction/:id/labelgroup',
    name: 'labelgroup',
    component: LabelGroupView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/:id/labelgroup/:itemId',
    name: 'LabelgroupConfig',
    component: LabelGroupView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/:id/datapoints',
    name: 'datapoints',
    component: DatapointView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/:id/datapoints/:itemId',
    name: 'DatapointConfig',
    component: DatapointView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/:id/rules/:ruleid',
    name: 'ConfigureBusinessRule',
    component: ConfigureBusinessRule,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/external/studio/agents/extraction/:id/rules/:token',
    name: 'ExternalConfigureBusinessRule',
    component: ConfigureBusinessRule,
    meta: {
      menu: false,
    },
  },
  {
    path: '/studio/agents/extraction/:id/:tab',
    name: 'DocType',
    component: DocTypeView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/:id/datapoints/from_model',
    name: 'FromModel',
    component: FromModel,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/agents/extraction/:id/files',
    name: 'files',
    component: DocTypeView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/datasets',
    name: 'DatasetsView',
    component: DatasetsView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/datasets/create',
    name: 'CreateDatasetsView',
    component: DatasetsView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/models/classification',
    name: 'Classification',
    component: ClassificationView,
    meta: {
      product: 'classify',
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/models/classification/training',
    name: 'ClassificationTrainingView',
    component: ClassificationTrainingView,
    meta: {
      product: 'classify',
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/models/classification/:name',
    name: 'ClassifierView',
    component: ClassifierInfo,
    meta: {
      product: 'classify',
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/datasets/:id',
    name: 'Dataset',
    component: DatasetTable,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/datasets/:id/annotate',
    name: 'Annotation',
    component: AnnotationView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/models/extraction',
    name: 'Extraction',
    component: ExtractionView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/models/extraction/training',
    name: 'ExtractionTraining',
    component: ExtractionTrainingView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/models/extraction/training/:id',
    name: 'ExtractionTrainingDataset',
    component: ExtractionTrainingView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/production/stats',
    name: 'Stats',
    component: StatsView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/traces',
    name: 'TracesView',
    component: TracesView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    }
  },
  {
    path: '/settings/general',
    name: 'Settings',
    component: SettingsView,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/settings/bin',
    name: 'RecycleBin',
    component: RecycleBinView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/settings/users',
    name: 'ExtractUsers',
    component: UsersView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/production/review',
    name: 'Corrections',
    component: CorrectionsView,
    meta: {
      roles: ['orgadmin', 'basic'],
      menu: true,
    },
  },
  {
    path: '/studio/workflows',
    name: 'Workflows',
    component: WorkflowsView,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: RedirectView,
  },
  {
    path: '/studio/workflows/configure/:id',
    name: 'ConfigureWorkflow',
    component: ConfigureWorkflow,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/workflows/mailboxes/:id',
    name: 'ConfigureMailbox',
    component: ConfigureMailbox,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/workflows/history/:id',
    name: 'JobHistory',
    component: JobHistory,
    meta: {
      roles: ['orgadmin'],
      menu: true,
    },
  },
  {
    path: '/studio/workflows/page_correction/:id',
    name: 'PageCorrection',
    component: PageCorrectionView,
    meta: {
      menu: true,
    },
  },
  {
    path: '/production/review/:id',
    name: 'CorrectionDocs',
    component: CorrectionDocsView,
    meta: {
      roles: ['orgadmin', 'basic'],
      menu: true,
    },
  },
  {
    path: '/production/review/:id/file/:fileId',
    name: 'DocCorrections',
    component: ReviewDocView,
    meta: {
      roles: ['orgadmin', 'basic'],
      menu: true,
    },
  },
  {
    path: '/corrections/file/:token',
    name: 'ExternalCorrection',
    component: ReviewDocView,
    meta: {
      menu: false,
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: NotificationsView,
    meta: {
      roles: ['sysadmin', 'orgadmin'],
      menu: true,
    },
  },
  {
    path: '/change-password',
    name: 'ExtractPassword',
    component: ProfilePassword,
    meta: {
      name: 'Change password',
      authorize: true,
      roles: ['sysadmin', 'orgadmin', 'basic'],
      menu: true,
    },
  },
];

const classifyRoutes = [
  {
    path: '/',
    name: 'Classify',

    component: ClassifyHomeView,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/home',
    name: 'ClassifyHome',
    component: ClassifyHomeView,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/login',
    name: 'ClassifyLogin',
    component: LoginView,
    meta: {
      menu: false,
    },
  },
  {
    path: '/settings/general',
    name: 'ClassifySettings',
    component: ClassifySettings,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/users',
    name: 'ClassifyUsers',
    component: UsersView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/change-password',
    name: 'ClassifyPassword',
    component: ProfilePassword,
    meta: {
      name: 'Change password',
      authorize: true,
      roles: ['sysadmin', 'orgadmin', 'basic'],
      menu: true,
    },
  },
];

const searchRoutes = [
  {
    path: '/',
    name: 'Search',
    component: SearchDocView,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/home',
    name: 'SearchHome',
    component: SearchQueryView,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/documents',
    name: 'SearchDocuments',
    component: SearchDocView,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/login',
    name: 'SearchLogin',
    component: LoginView,
    meta: {
      menu: false,
    },
  },
  {
    path: '/settings/general',
    name: 'SearchSettings',
    component: SearchSettings,
    meta: {
      roles: ['basic', 'orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/users',
    name: 'SearchUsers',
    component: UsersView,
    meta: {
      roles: ['orgadmin', 'sysadmin'],
      menu: true,
    },
  },
  {
    path: '/change-password',
    name: 'SearchPassword',
    component: ProfilePassword,
    meta: {
      name: 'Change password',
      authorize: true,
      roles: ['sysadmin', 'orgadmin', 'basic'],
      menu: true,
    },
  },
];

const prefixedClassifyRoutes = prefixRoutesWith(classifyRoutes, '/classify');
const prefixedSearchRoutes = prefixRoutesWith(searchRoutes, '/search');
const prefixedExtractRoutes = prefixRoutesWith(extractRoutes, '/extract');

const routes = [
  ...prefixedExtractRoutes,
  ...prefixedSearchRoutes,
  ...prefixedClassifyRoutes,
  ...commonRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  let isLoggedIn = false;
  let userProducts = [];
  const noAuth = [
    'ExtractLogin',
    'ExtractLoginOrg',
    'ExtractLoginOrgSlug',
    'SearchLogin',
    'ClassifyLogin',
    'logout',
    'TokenValidation',
    'ResetPassword',
    'LostPassword',
    '404',
    '404Redirect',
    'ExternalCorrection',
    'ExternalConfigureBusinessRule'
  ];

  let requestedProduct = '';
  if (to.path.startsWith('/extract')) {
    requestedProduct = 'extract';
  } else if (to.path.startsWith('/search')) {
    requestedProduct = 'search';
  } else if (to.path.startsWith('/classify')) {
    requestedProduct = 'classify';
  }
  const products = ['classify', 'extract', 'search'];
  if (products.includes(requestedProduct)) {
    // We check if product's services are running
    const mainServiceRunning = await ProductHealthCheck.check(requestedProduct);

    // Product's main service is not running
    if (!mainServiceRunning) {
      return next({ name: '404Redirect' });
    }
  }

  if (!noAuth.includes(to.name)) {
    isLoggedIn = await AuthenticationUtils.isLoggedIn();
    if (isLoggedIn) {
      userProducts = store.getters.loggedInUser.product;
      // If user attempts to access product route that's not authorized, we throw 404.
      if (requestedProduct !== '' && !userProducts.includes(requestedProduct)) {
        return next({ name: '404Redirect' });
      }

      // In Search we redirect to Search home
      else if (['/search/', '/search'].includes(to.path)) {
        return next('/search/home');
      }
      // In Classify we redirect to Classify home
      else if (['/classify/', '/classify'].includes(to.path)) {
        return next('/classify/home');
      }
      // In Extract we redirect to Extract home
      if (['/extract/', '/extract'].includes(to.path)) {
        return next('/extract/home');
      }
    }
    if (!isLoggedIn) {
      // not logged in so redirect to login page with the return url
      const loginPath = `/${products.includes(requestedProduct) ? requestedProduct : product.toLowerCase()}/login`;
      return next({ path: loginPath });
    }
    // check if route is restricted by role or product
    if (
      store.getters.loggedInUser && (
        to.meta.roles && !to.meta.roles.includes(store.getters.loggedInUser.role) ||
        to.params && to.params.tab === 'classifiers' && !userProducts.includes('classify') ||
        to.meta.product === 'classify' && !userProducts.includes('classify')
      )
    ) {
      // role not authorized so redirect to home page
      return next({ path: '/' });
    }
  }
  return next();
});

export default router;
