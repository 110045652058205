import { store } from '@/plugins/store';
import { ClassifyHealthAPI } from '@/API/classify/ClassifyHealthAPI';
import { ExtractHealthAPI } from '@/API/extract/ExtractHealthAPI';
import { SearchHealthAPI } from '@/API/search/SearchHealthAPI';
import { WorkflowHealthAPI } from '@/API/workflows/WorkflowHealthAPI';

class ProductHealthCheckClass {

  static async check(product) {
    const productServices = {
      extract: ['extract', 'workflows', 'classify'],
      classify: ['classify'],
      search: ['search'],
    };

    const productMainService = {
      extract: 'extract',
      classify: 'classify',
      search: 'search',
    }

    const serviceHealthAPI = {
      extract: ExtractHealthAPI,
      classify: ClassifyHealthAPI,
      search: SearchHealthAPI,
      workflows: WorkflowHealthAPI,
    };

    const services = productServices[product];
    const mainService = productMainService[product];
    let productRunning = true;

    for (let i = 0; i < services.length; i++) {
      const service = services[i];
      const status = store.getters.serviceStatus[service];
      if (!status.checked) {
        try {
          await serviceHealthAPI[service].get();
        } catch {
          store.commit('setServiceStatusUnreachable', service);
          if (service === mainService) {
            productRunning = false;
          }
        } finally {
          store.commit('setServiceStatusChecked', service);
        }
      } else if (service === mainService) {
        return status.running;
      }
    }

    return productRunning;
    }
  }
  
  export const ProductHealthCheck = ProductHealthCheckClass;
  